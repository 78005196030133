import { Link } from "gatsby";
import React from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import styles from "../../styles/info-site/RewardsIntegrationGuide.module.css"
import TextInfo from "../styleguide/TextInfo";
import SimpleStage from "./SimpleStage";
import Faqs from "../styleguide/Faqs";
import GetStarted from "./GetStarted";
import TyvisoStudioLogo from "../../assets/tyviso-studio.png";
import TyvisoStudio from "./TyvisoStudio";
import SwitchComponent from "../styleguide/Switch";
import BoohooLogo from "../../assets/boohoo-logo.svg";
import NakedWinesLogo from "../../assets/nakedWines.jpg";
import GrenadeLogo from "../../assets/grenade-logo.svg";
import GoustoLogo from "../../assets/gousto-logo.png";
import IcelandLogo from "../../assets/iceland-logo.png";
import ISawFirstLogo from "../../assets/isawitfirst-logo.jpg";
import MyProteinLogo from "../../assets/myprotein-logo.jpg";
import TailsLogo from "../../assets/tails-logo.png";
import BoohooScreenshot from "../../assets/boohoo-client-setup.jpg";
import NakedWinesScreenshot from "../../assets/naked-wines-setup.jpg";
import GrenadeScreenshot from "../../assets/screencapture-grenade.png";
import GoustoScreenshot from "../../assets/gousto-adv-setup.png";
import IcelandScreenshot from "../../assets/iceland-adv-setup.png";
import ISawFirstScreenshot from "../../assets/i-saw-it-first-adv-setup.png";
import MyProteinScreenshot from "../../assets/myprotein-adv-setup.png";
import TailsScreenshot from "../../assets/tails-adv-setup.png";
import Testimonials from "../styleguide/Testimonials";
import VideoPlayer from "../styleguide/VideoPlayer";
import WildLogo from "../../assets/WildLogo.svg"
import JDSportsLogo from "../../assets/jd-logo-large-en-dark.png"
import VisionExpressLogo from "../../assets/vision-express.png"
// import SecretSalesLogo from "../../assets/secret-sales.jpg"
import SecretSalesLogo from "../../assets/secret-sales-2.png"
import CurrysLogo from "../../assets/brand-currys-logo.svg"
import AoLogo from "../../assets/ao.com_logo.png"


const RewardsIntegrationGuide = ({ hero, steps, host, handleHost, hostFaq, advFaq, testimonials, type = "reward" }) => {
    const partners = [
        "Boohoo",
        "NakedWines",
        "Grenade"
    ];

    const supplierPartners = [
        "Gousto",
        "Iceland",
        "ISawFirst",
        "MyProtein",
        "Tails",
    ];

    const giftHostPartners = [
      'Wild',
      'JDSports',
      'VisionExpress',
      'SecretSales',
      'Currys',
      'AO'
    ]
    
    const logos = {
        Boohoo: BoohooLogo,
        NakedWines: NakedWinesLogo,
        Grenade: GrenadeLogo,
    };
    
    const supplierLogos = {
        Gousto: GoustoLogo,
        Iceland: IcelandLogo,
        ISawFirst: ISawFirstLogo,
        MyProtein: MyProteinLogo,
        Tails: TailsLogo,
    };
    
    const giftHostLogos = {
      Wild: WildLogo,
      JDSports: JDSportsLogo,
      VisionExpress: VisionExpressLogo,
      SecretSales: SecretSalesLogo,
      Currys: CurrysLogo,
      AO: AoLogo,
    };

    const screenshots = {
        Boohoo: BoohooScreenshot,
        NakedWines: NakedWinesScreenshot,
        Grenade: GrenadeScreenshot,
    };

    const supplierScreenshots = {
        Gousto: GoustoScreenshot,
        Iceland: IcelandScreenshot,
        ISawFirst: ISawFirstScreenshot,
        MyProtein: MyProteinScreenshot,
        Tails: TailsScreenshot,
    };

    const giftHostIframes = {
      Wild: "https://tyviso-gwp-widgets.netlify.app/wild/demo/checkout.html",
      JDSports: "https://tyviso-gwp-widgets.netlify.app/jdsports/demo/cart.html",
      VisionExpress: "https://tyviso-gwp-widgets.netlify.app/visionexpress/demo/checkout.html",
      SecretSales: "https://tyviso-gwp-widgets.netlify.app/secretsales/test/cart.html",
      Currys: "https://tyviso-gwp-widgets.netlify.app/currys-uk/demo/cart.html",
      AO: "https://tyviso-gwp-widgets.netlify.app/ao/demo/checkout.html",
    }

    const hostExamples = {
      title: "tyvisoStudioHostDescription",
      logos,
      screenshots,
      partners: partners,
    }

    const supplierExamples = {
      title: "tyvisoStudioSupplierDescription",
      logos: supplierLogos,
      screenshots: supplierScreenshots,
      partners: supplierPartners,
    }

    const giftExamples = {
      title: "tyvisoStudioGiftHostDescription",
      logos: giftHostLogos,
      iframes: giftHostIframes,
      partners: giftHostPartners,
    }

    return (
        <div className={styles.IntegrationGuide}>
            {
              hero && (
                <SimpleStage>
                    <TextInfo className={styles.IntegrationGuideStage} title={hero.title} description={hero.description} />
                    <div className={styles.IntegrationGuideContact}>
                        <FormattedMessage id={hero.questions} />
                        <Link to={hero.link.url}><FormattedMessage id={hero.link.text} /></Link>
                    </div>
                </SimpleStage>
              )
            }
            <div className={styles.SetupSwitchWrapper}>
              <span data-title='Host' className={`${styles.SetupSwitchLabel} ${host ? styles.SetupSwitchLabel__checked : ''}`}>Host</span>
              <SwitchComponent className={styles.SetupSwitch} checked={!host} onChange={handleHost} />
              <span data-title='Supplier' className={`${styles.SetupSwitchLabel} ${!host ? styles.SetupSwitchLabel__checked : ''}`}>Supplier</span>
            </div>
            <div className={styles.IntegrationGuideSteps}>
              {
                steps && steps.length > 0 && steps.map(step => (
                  <div key={step.id} className={styles.IntegrationGuideStepsItem}>
                    <div className={styles.IntegrationGuideStepsItemContent}>
                        <span className={styles.IntegrationGuideStepsItemStepNumber}><FormattedMessage id={step.label} /></span>
                        <h3><FormattedMessage id={step.title} /></h3>
                        <p><FormattedHTMLMessage id={step.description} /></p>
                        <div className={styles.IntegrationGuideStepsItemLinks}>
                          {
                            step.links && step.links.length > 0 &&
                              step.links.map(link => /https:\/\//.test(link.url)
                                ? (
                                  <a key={link.id} href={link.url} {...(link.target ? { rel: "noreferrer", target: "_blank" } : {})}><FormattedMessage id={link.label} /></a>
                                  ) : (
                                  <Link key={link.id} to={link.url}>
                                    <FormattedMessage id={link.label} />
                                  </Link>
                                )
                              )
                          }
                        </div>
                    </div>
                    <div className={`${styles.IntegrationGuideStepsItemImage} ${type === "gift" ? styles.IntegrationGuideStepsItemImage__gift : ""}`}>
                      <img src={step.image.src} alt={step.image.alt} />
                    </div>
                </div>
                ))
              }
            </div>
            {
              testimonials && <Testimonials partners={testimonials.partners} showPersonName={testimonials.showPersonName} />
            }
            <TyvisoStudio
              host={host}
              logo={{
                src: TyvisoStudioLogo,
                alt: "Tyviso Studio"
              }}
              withIframe={type === "gift"}
              {
                ...(
                  type === "gift"
                    ? giftExamples
                    : host ? hostExamples : supplierExamples
                )
              }
            />
            <VideoPlayer
              className={styles.IntegrationGuideStageVideo}
              autoPlayFull
              mp4Video={
                host
                  ? "https://tyviso.fra1.digitaloceanspaces.com/tyviso/rewards/video/Tyviso%20Rewards%20-%20Guided%20setup%20video.mp4"
                  : "https://tyviso.fra1.digitaloceanspaces.com/tyviso/rewards/video/Tyviso%20Rewards%20Demo%20Video%20-%20Advertiser%20%28Part%201%29.mp4"
              }
              webVideo={null}
            />
            <div className={styles.IntegrationGuideFaqs}>
                <h4 className={styles.IntegrationGuideFaqsTitle}><FormattedMessage id="faqTitle" /></h4>
                <Faqs faqData={host ? hostFaq : advFaq} />
            </div>
            <GetStarted ctaText="goLiveQuickly" />
        </div>
    );
};

export default RewardsIntegrationGuide;