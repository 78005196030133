import React, { useState } from 'react'
import { FormattedMessage } from "react-intl";
import HomepageStage from "../../assets/homepage-stage-formatted.png";
import styles from "./VideoPlayer.module.css";

const VideoPlayer = ({
  className,
  youtube,
  mp4Video = "https://tyviso.com/assets/tyviso-demo-v2.mp4",
  webVideo = "https://tyviso.com/assets/tyviso-demo-v2.webm",
  autoPlayFull = false
  }) => {
  const [fullScreen, setFullScreen] = useState(false);
 
  const renderVideo = () => {
      return (
          <video autoPlay={true} muted={true} loop={true}>
              {mp4Video && <source src={mp4Video} type="video/mp4" />}
              {webVideo && <source src={webVideo} type="video/webm" />}
              
          </video>
      );
  };

  const renderFullScreenVideo = () => {
      return (
          <video autoPlay={autoPlayFull} controls={true}>
              {mp4Video && <source src={mp4Video} type="video/mp4" />}
              {webVideo && <source src={webVideo} type="video/webm" />}
          </video>
      );
  }

  return (
    <div className={`${styles.VideoPlayerWrapper} ${className ? className : ''}`}>
      <div className={styles.VideoPlayer}>
        <div className={styles.VideoPlayerContent}>
          {
            youtube
              ? <iframe src={youtube.src} title={youtube.title} frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              : (
                <>
                    <span className={styles.VideoPlayerLink} onClick={() => setFullScreen(true)}>
                        <FormattedMessage id="watchDemo" />
                    </span>
                    {renderVideo()}
                </>
              )
          }
          {
              fullScreen &&
              <div className={styles.VideoPlayerFullScreen}>
                  <div className={styles.VideoPlayerFullScreenPlaceholder}>
                      <span className={styles.VideoPlayerFullScreenClose} onClick={() => setFullScreen(false)}>
                          <FormattedMessage id="closeDemo" />
                      </span>
                      {renderFullScreenVideo()}
                  </div>
              </div>
          }
        </div>
        <img className={styles.VideoPlayerContainerImg} src={HomepageStage} alt="" />
      </div>
    </div>
  )
}

export default VideoPlayer