import React, { useState } from "react";
import RewardsIntegrationGuide from "../components/info-site/RewardsIntegrationGuide";
import Seo from "../components/info-site/Seo";
import Layout from "../layouts/en";

import Step1 from "../assets/step-gwp-1.png";
import Step2 from "../assets/step-gwp-2.png";
import Step3 from "../assets/step-gwp-3.png";
import Step4 from "../assets/step-gwp-4.webp";
import Step5 from "../assets/step-3.png";
import StepHost5 from "../assets/step-5-host.png"
import StepAdv2 from "../assets/step-2-adv.png";

import { gwpHostFaq, integrationSupplierGuideFaqData } from "../data/integrationGuideFaqData";

const SetupGuide = props => {
    const [host, setHost] = useState(true);

    const handleHost = () => {
      setHost(!host)
    }
    return (
        <Layout location={props.location}>
            <Seo titleId="gwpGuideTitle" descriptionId="gwpGuideDescription" />

            <RewardsIntegrationGuide
              host={host}
              handleHost={handleHost}
              hostFaq={gwpHostFaq}
              advFaq={integrationSupplierGuideFaqData}
              type="gift"
              hero={{
                title: "gwpGuideTitle",
                description: "integrationGuideDescription",
                questions: "integrationGuideQuestions",
                link: {
                  url: "/contact#contact-form",
                  text: "emailUs"
                }
              }}
              testimonials={
                host
                  ? null
                  : {
                    partners: ["SimplyCook"],
                    showPersonName: true
                  }
              }
              steps={
                host
                  ? [
                    {
                      id: "rewards-step-1",
                      label: "stepOne",
                      title: "gwpGuideHostStepOneTitle",
                      description: "gwpGuideHostStepOneDescription",
                      links: [
                        {
                          id: 'integration-step-guide-link-2',
                          target: "_blank",
                          url: "https://rtbmedia.hubspotpagebuilder.com/book-a-demo",
                          label: "gwpGuideHostStepOneLinkTwo"
                        },
                      ],
                      image: {
                        alt: "GwpStep 1",
                        src: Step1
                      }
                    },
                    {
                      id: "rewards-step-2",
                      reversed: true,
                      label: "stepTwo",
                      title: "gwpGuideHostStepTwoTitle",
                      description: "gwpGuideHostStepTwoDescription",
                      image: {
                        alt: "GwpStep 2",
                        src: Step2
                      }
                    },
                    {
                      id: "rewards-step-3",
                      label: "stepThree",
                      title: "gwpGuideHostStepThreeTitle",
                      description: "gwpGuideHostStepThreeDescription",
                      image: {
                        alt: "GwpStep 3",
                        src: Step3
                      }
                    },
                    {
                      id: "rewards-step-4",
                      label: "stepFour",
                      title: "gwpGuideHostStepFourTitle",
                      description: "gwpGuideHostStepFourDescription",
                      image: {
                        alt: "GwpStep 4",
                        src: Step4
                      }
                    },
                    {
                      id: "rewards-step-5",
                      label: "stepFive",
                      title: "gwpGuideHostStepFiveTitle",
                      description: "gwpGuideHostStepFiveDescription",
                      image: {
                        alt: "GwpStep 5",
                        src: StepHost5
                      }
                    },
                  ] : [
                    {
                      id: "rewards-step-1",
                      label: "stepOne",
                      title: "gwpGuideHostStepOneTitle",
                      description: "gwpGuideHostStepOneDescription",
                      links: [
                        {
                          id: 'integration-step-guide-link-1',
                          target: "_blank",
                          url: "https://www.youtube.com/watch?v=GZs7m0-u_YM",
                          label: "gwpGuideHostStepOneLinkOne"
                        },
                        {
                          id: 'integration-step-guide-link-2',
                          target: "_blank",
                          url: "https://rtbmedia.hubspotpagebuilder.com/book-a-demo",
                          label: "gwpGuideHostStepOneLinkTwo"
                        },
                      ],
                      image: {
                        alt: "GwpStep 1",
                        src: Step1
                      }
                    },
                    {
                      id: "rewards-step-2",
                      reversed: true,
                      label: "stepTwo",
                      title: "gwpGuideSupplyStepTwoTitle",
                      description: "gwpGuideSupplyStepTwoDescription",
                      image: {
                        alt: "GwpStep 2",
                        src: StepAdv2
                      }
                    },
                    {
                      id: "rewards-step-3",
                      label: "stepThree",
                      title: "gwpGuideSupplyStepThreeTitle",
                      description: "gwpGuideSupplyStepThreeDescription",
                      image: {
                        alt: "GwpStep 3",
                        src: Step5
                      }
                    },
                  ]
              }
            />
        </Layout>
    );
};

export default SetupGuide;