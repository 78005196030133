import React from 'react'

import style from './Switch.module.css'

const SwitchComponent = ({ className, checked = false, onChange }) => {
  const classNames = `${style.ToggleSwitch} ${className ? className : ''}`;

  return (
    <div className={classNames}>
      <input onChange={onChange} checked={checked} className={style.ToggleSwitchCheckbox} type='checkbox' />
      <div className={style.ToggleSwitchRoundButton} />
    </div>
  )
}

export default SwitchComponent
