import React from "react";
import styles from "../../styles/info-site/SimpleStage.module.css";
import StageLogo from "../../assets/stage-logo.png";

const SimpleStage = ({children}) => {
    return (
        <div className={styles.SimpleStage}>
            <div className={styles.SimpleStagePlaceholder} style={{backgroundImage: `url(${StageLogo})`}}>
                {children}
            </div>
        </div>
    );
};

export default SimpleStage;