import React, { useRef, useState, useEffect } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import styles from "../../styles/info-site/TyvisoStudio.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons";

const TyvisoStudio = ({
  logo,
  title,
  description,
  logos,
  screenshots,
  iframes,
  partners,
  host,
  withIframe
}) => {
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [chosenPartner, setChosenPartner] = useState("");
    const screenshotsElement = useRef(null);
    const tyvisoStudioElement = useRef(null);

    useEffect(() => {
      setChosenPartner("")
    }, [host])
    

    const openScreenshot = partner => {
        setChosenPartner(partner);

        setTimeout(() => {
            screenshotsElement.current.scrollIntoView({ behavior: "smooth" });
        }, 250);
    };

    const closeScreenshot = () => {
        setChosenPartner("");
        tyvisoStudioElement.current.scrollIntoView({ behavior: "smooth" });
    };

    const prevImage = () => {
        const index = partners.indexOf(chosenPartner);
        screenshotsElement.current.scrollIntoView({ behavior: "smooth" });
        
        
        setChosenPartner(partners[index - 1] ? partners[index - 1] : partners[partners.length - 1]);
    };

    const nextImage = () => {
        const index = partners.indexOf(chosenPartner);
        screenshotsElement.current.scrollIntoView({ behavior: "smooth" });
        
        setChosenPartner(partners[index + 1] ? partners[index + 1] : partners[0]);
    }

    const hideSpinner = () => {
      setSpinnerLoading(false);
    }

    return (
        <div className={styles.TyvisoStudio} ref={tyvisoStudioElement}>
            <img src={logo.src} alt={logo.alt} className={styles.TyvisoStudioLogo} />
            {title && <h3 className={styles.TyvisoStudioTitle}><FormattedMessage id={title} /></h3>}
            {description && <p className={styles.TyvisoStudioDescription}><FormattedHTMLMessage id={description} /></p>}
            {
              partners && partners.length > 1 && (
                <div className={styles.TyvisoStudioLogos}>
                    {partners.map((partner, i) => <div className={styles.TyvisoStudioLogosItem} key={i}><img src={logos[partner]} onClick={() => openScreenshot(partner)} /></div>)}
                </div>
              )
            }
            {
                !!chosenPartner &&
                <div className={styles.TyvisoStudioScreenshots} ref={screenshotsElement}>
                    <div className={styles.TyvisoStudioScreenshotsHeader}>
                        <img className={styles.TyvisoStudioScreenshotsLogo} src={logos[chosenPartner]} alt={chosenPartner} />
                        <span className={styles.TyvisoStudioScreenshotsClose} onClick={closeScreenshot}><FontAwesomeIcon icon={faTimes} /></span>
                    </div>
                    <div className={`${styles.TyvisoStudioScreenshotsImagePlaceholder} ${withIframe ? styles.TyvisoStudioScreenshotsImagePlaceholder__wide : ''}`}>
                        <span className={styles.TyvisoStudioScreenshotsPrev} onClick={prevImage}><FontAwesomeIcon icon={faArrowLeft} /></span>
                        <div className={`${styles.TyvisoStudioScreenshotsImageDiv} ${withIframe ? styles.TyvisoStudioScreenshotsImageDiv__wide : ''}`}>
                            {
                              screenshots && <img className={styles.TyvisoStudioScreenshotsImage} src={screenshots[chosenPartner]} alt="Screenshot" />
                            }
                            {
                              spinnerLoading && (
                                <div className={styles.SpinnerWrapper}>
                                  <div className={styles.Spinner}>
                                    <div className={styles.SpinnerPoint}></div>
                                    <div className={styles.SpinnerPoint}></div>
                                    <div className={styles.SpinnerPoint}></div>
                                    <div className={styles.SpinnerPoint}></div>
                                    <div className={styles.SpinnerPoint}></div>
                                    <div className={styles.SpinnerPoint}></div>
                                    <div className={styles.SpinnerPoint}></div>
                                    <div className={styles.SpinnerPoint}></div>
                                  </div>
                                </div>
                              )
                            } 
                            {
                              iframes && (
                                <iframe
                                  src={iframes[chosenPartner]}
                                  onLoad={hideSpinner}
                                  height="800px"
                                />
                              )
                            }
                            
                        </div>
                        <span className={styles.TyvisoStudioScreenshotsPrev} onClick={nextImage}><FontAwesomeIcon icon={faArrowRight} /></span>
                    </div>
                </div>
            }
        </div>
    );
};

export default TyvisoStudio;