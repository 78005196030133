export const integrationHostGuideFaqData = [
    {titleLabel: "integrationHostGuideFaqOneTitle", descriptionLabel: "integrationHostGuideFaqOneDescription"},
    {titleLabel: "integrationGuideFaqTwoTitle", descriptionLabel: "integrationGuideFaqTwoDescription"},
    {titleLabel: "integrationHostGuideFaqTwoTitle", descriptionLabel: "integrationHostGuideFaqTwoDescription"},
    {titleLabel: "integrationGuideFaqSixTitle", descriptionLabel: "integrationGuideFaqSixDescription"},
];

export const integrationSupplierGuideFaqData = [
  {titleLabel: "integrationSupplierGuideFaqOneTitle", descriptionLabel: "integrationSupplierGuideFaqOneDescription"},
  {titleLabel: "integrationSupplierGuideFaqTwoTitle", descriptionLabel: "integrationSupplierGuideFaqTwoDescription"},
  {titleLabel: "integrationSupplierGuideFaqThreeTitle", descriptionLabel: "integrationSupplierGuideFaqThreeDescription"},
  {titleLabel: "integrationSupplierGuideFaqFourTitle", descriptionLabel: "integrationSupplierGuideFaqFourDescription"},
];

export const integrationGuideFaqData = [
  {titleLabel: "integrationGuideFaqOneTitle", descriptionLabel: "integrationGuideFaqOneDescription"},
  {titleLabel: "integrationGuideFaqTwoTitle", descriptionLabel: "integrationGuideFaqTwoDescription"},
  {titleLabel: "integrationGuideFaqFourTitle", descriptionLabel: "integrationGuideFaqFourDescription"},
  {titleLabel: "integrationGuideFaqFiveTitle", descriptionLabel: "integrationGuideFaqFiveDescription"},
  {titleLabel: "integrationGuideFaqSixTitle", descriptionLabel: "integrationGuideFaqSixDescription"},
];

export const gwpHostFaq = [
  {titleLabel: "gwpHostFaqOneTitle", descriptionLabel: "gwpHostFaqOneDescription"},
  {titleLabel: "gwpHostFaqTwoTitle", descriptionLabel: "gwpHostFaqTwoDescription"},
  {titleLabel: "gwpHostFaqThreeTitle", descriptionLabel: "gwpHostFaqThreeDescription"},
  {titleLabel: "gwpHostFaqFourTitle", descriptionLabel: "gwpHostFaqFourDescription"},
];
